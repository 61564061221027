export const candidatHeaders = [
  {
    text: "TAC",
    value: "trigrammeTAC",
    sortable: false,
    align: 'center',
    width: '2%'
  },
  {
    text: "Nom",
    value: "nom",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "Prenom",
    value: "prenom",
    sortable: false,
    align: 'center',
    width: '8%'
  },
  {
    text: "Date de disponibilité",
    value: "disponibilite",
    sortable: false,
    align: 'center',
    width: '6%'
  },
  // {
  //   text: "ASAP",
  //   value: "ASAP",
  //   sortable: false,
  //   align: 'center',
  //   width: '2%'
  // },
  {
    text: "Emploi",
    value: "profil",
    sortable: false,
    align: 'center',
    width: '20%'
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    sortable: false,
    align: 'center',
    width: '5%'
  },
  
  {
    text: "Statut",
    value: "statut",
    sortable: false,
    align: 'center',
    width: '5%'
  },  
  {
    text: "Prequa. tel",
    value: "prequa_tel",
    sortable: false,
    align: 'center',
    width: '13%'
  },
  {
    text: "Expertise",
    value: "expertise",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "Souhait",
    value: "souhait",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "Pôle",
    value: "pole",
    sortable: false,
    align: 'center',
    width: '6%'
  },
  {
    text: "Chasse",
    value: "source_chasse",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "Transverse",
    value: "transverse",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "Cooptation",
    value: "cooptation",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "Province",
    value: "label_ville_province",
    sortable: false,
    align: 'center',
    width: '6%'
 },
  {
    text: "Remontée LinkedIn",
    value: "is_linkedin",
    sortable: false,
    align: 'center',
    width: '10%'
  },
  {
    text: "mc2i Opp",
    value: "mc2i_opp",
    sortable: false,
    align: 'center',
    width: '6%'
 },
 {
  text: "Data",
  value: "data",
  sortable: false,
  align: 'center',
  width: '6%'
},
  { text: "Actions",
    value: "actions",
    sortable: false, 
    width: '5%'
  }
];
