export const candidatsFilters = [
  {
    text: "TAC",
    value: "trigrammeTAC", 
    selected: []
  },
  {
    text: "Disponibilité",
    value: "disponibilite",
    selected: []
  },
  {
    text: "Profil",
    value: "profil",
    selected: []
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    selected: []
  },
  {
    text: "Statut",
    value: "statut",
    selected: ["OK"]
  },
  {
    text: "Préqua. tel",
    value: "prequa_tel",
    selected: []
  },
  {
    text: "Pôle",
    value: "pole",
    selected: [],
    
  },
  {
    text: "Source chasse",
    value: "source_chasse",
    selected: []
  },
  {
    text: "Transverse",
    value: "transverse",
    selected: []
  },
  {
    text: "Cooptation",
    value: "cooptation",
    selected: []
  },
  {
    text: "Province",
    value: "label_ville_province",
    selected: []
  },
  {
    text: "Linkedin",
    value: "is_linkedin",
    selected: []
  },
  {
    text: "mc2i Opp",
    value: "mc2i_opp",
    selected: []
  },
  {
    text: "Data",
    value: "data",
    selected: []
  }
]; 